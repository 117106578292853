<template>
  <v-card class="mx-auto pa-0" :max-width="maxWidth" flat>
    <v-card-title class="justify-center">
      Your Risk Score Advisor
    </v-card-title>
    <v-divider></v-divider>
    <div
      class="
        d-flex
        flex-column flex-sm-row flex-lg-column
        justify-center
        align-center
      "
    >
      <v-row no-gutters align="center" class="pa-4" style="width: 100%">
        <v-col
          class="d-flex justify-center col-3 mr-2"
          v-if="advisorCompanyLogo || advisorAvatar"
        >
          <!-- <v-avatar size="72">
          <v-img v-if="advisorAvatar" :src="advisorAvatar"></v-img>
         
        </v-avatar> -->
          <img v-if="advisorAvatar" :src="advisorAvatar" style="width: 72px" />
        </v-col>
        <v-col class="pl-2">
          <template
            :class="!advisorAvatar && !advisorCompanyLogo ? 'pa-4' : 'pa-0'"
          >
            <div class="pt-0 text-h6">
              {{ advisor.firstName || advisor.first_name }}
              {{ advisor.lastName || advisor.last_name }}
            </div>
            <div class="subtitle-2">
              <div v-if="advisor.credentials">
                {{ advisor.credentials }}
              </div>
              <div v-if="advisor.email">
                <a :href="`mailto:${advisor.email}`">{{ advisor.email }}</a>
              </div>
              <div v-if="advisor.phone">
                <a :href="`tel:${advisor.phone}`">{{
                  formatPhone(advisor.phone)
                }}</a>
              </div>
            </div>
          </template>

          <!-- <v-list-item
            color="rgba(0, 0, 0, .4)"
            :class="!advisorAvatar && !advisorCompanyLogo ? 'pa-4' : 'pa-0'"
          >
            <v-list-item-content>
              <v-list-item-title class="pt-0 text-h6">
                {{ advisor.firstName || advisor.first_name }}
                {{ advisor.lastName || advisor.last_name }}
              </v-list-item-title>

              <v-list-item-subtitle>{{
                advisor.credentials
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <a :href="`mailto:${advisor.email}`">{{ advisor.email }}</a>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a :href="`tel:${advisor.phone}`">{{ advisor.phone }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider> -->
      <v-row no-gutters align="center" class="pa-4" style="width: 100%">
        <v-col
          v-if="advisorCompanyLogo"
          class="d-flex justify-center col-3 mr-2"
        >
          <!-- <v-avatar size="48" tile>
          <v-img contain :src="advisorCompanyLogo"></v-img>
          
        </v-avatar> -->
          <img :src="advisorCompanyLogo" style="width: 48px" />
        </v-col>
        <v-col class="pl-2"
          ><div
            class="mb-3 subtitle-2"
            :class="!advisorCompanyLogo ? '' : 'pr-2'"
          >
            <span>{{ advisor.companyName }}</span
            ><br />
            <!-- check for deprecated companyAddress -->
            <div v-if="advisor.companyAddress">
              {{ advisor.companyAddress }}
            </div>
            <!-- end deprecated check -->
            <template
              v-if="advisor.address && !checkAllValuesEmpty(advisor.address)"
            >
              <div>{{ advisor.address.streetAddress1 }}</div>
              <div>{{ advisor.address.streetAddress2 }}</div>

              <div>
                {{ advisor.address.city
                }}<span v-if="advisor.address.city && advisor.address.state"
                  >,
                </span>
                {{ advisor.address.state }}
                {{ advisor.address.postalCode }}
              </div>
            </template>
            <a :href="advisor.companyUrl" target="_blank">{{
              advisor.companyUrl
            }}</a>
            <br /></div
        ></v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { Storage } from "aws-amplify";
import { mask } from "maska";

export default {
  props: {
    advisor: {
      type: Object,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
  },
  data() {
    return {
      advisorCompanyLogo: null,
      advisorAvatar: null,
    };
  },
  watch: {
    advisor: {
      immediate: true,
      handler(v) {
        if (v) {
          this.getS3Images();
        }
      },
    },
  },
  // mounted() {
  //   this.getS3Images();
  // },
  methods: {
    formatPhone(phone) {
      return mask(phone, "###-###-####");
    },
    checkAllValuesEmpty(obj) {
      return !Object.values(obj).some((x) => x !== null && x !== "");
    },
    async getS3Images() {
      if (this.advisor && this.advisor.companyLogo) {
        try {
          const companyLogoKey = this.advisor.companyLogo.key;
          const companyLogo = await Storage.get(companyLogoKey, {
            level: "public",
          });
          this.advisorCompanyLogo = companyLogo;
        } catch (error) {
          console.log("Error getting company logo: ", error);
        }
      }

      if (this.advisor && this.advisor.avatar) {
        try {
          const avatarKey = this.advisor.avatar.key;
          const avatar = await Storage.get(avatarKey, { level: "public" });

          this.advisorAvatar = avatar;
        } catch (error) {
          console.log("Error getting advisor avatar: ", error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
.rs-advisor {
  padding: 1rem;
  border: 1px solid black;

  &__avatar,
  &__companyLogo {
    max-width: 200px;
  }
}
</style>
