<template>
  <v-container>
    <header class="rs-header mt-7 mb-8 mb-lg-12 text-center">
      <h1 class="mx-auto text-body-1 text-md-h6 text-lg-h5 font-weight-bold">
        Take the Social Security Risk Score to determine if your retirement is
        at risk.
      </h1>
    </header>
    <div
      v-if="loading"
      class="d-flex flex-column align-center justify-center mt-12"
    >
      <h3 class="mb-8">Setting up your assessment...</h3>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card
      v-if="showMessage && !loading"
      outlined
      max-width="600"
      elevation="12"
      class="mx-auto py-8"
    >
      <v-card-title v-if="messageTitle" class="text-h5 justify-center">
        {{ messageTitle }}</v-card-title
      >
      <v-card-text class="text-h6 text-center">
        {{ message }}
      </v-card-text>
    </v-card>

    <template v-if="getAdvisor && !showMessage">
      <v-row v-if="getAdvisor.showAdvisorCard">
        <v-col class="col-12 col-lg-6">
          <h3 class="mb-4">
            <strong
              >Welcome. You have been invited by your financial advisor to
              determine your Social Security Risk Score.</strong
            >
          </h3>
          <p>
            The PlanGap Social Security Risk Score is a personalized assessment
            of both the likelihood and the impact of Social Security reductions
            built specifically for you.
          </p>

          <h3>Begin your Risk Score assessment now.</h3>
        </v-col>
        <v-col class="col-12 col-lg-6">
          <div class="mb-16">
            <!-- <p class="text-center">
              <strong>
                This Risk Score Assessment is presented to you by:
              </strong>
            </p> -->

            <AdvisorInfoCard
              :advisor="getAdvisor"
              :maxWidth="500"
            ></AdvisorInfoCard>
          </div>
        </v-col>
      </v-row>
      <v-card outlined tile elevation="0" class="mb-12 rs-score-survey-wrap">
        <plangap-risk-score
          :env="env"
          :advisor-id="getAdvisor ? getAdvisor.id : ''"
          :prospect-id="getProspect ? getProspect.id : ''"
          :id="getAdvisor.id"
          :vanity="advisorVanityName"
          :source="`rs-${env}`"
        ></plangap-risk-score>
      </v-card>
    </template>
  </v-container>
</template>
<script>
import { API, Auth } from "aws-amplify";
import { vanityInfoByVanityName, getProspect } from "@/graphql/queries";
import AdvisorInfoCard from "@/components/AdvisorInfoCard";
import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

export default {
  name: "PublicScoreSurvey",
  components: { AdvisorInfoCard },
  mixins: [getUserCredentialsMixin],
  data() {
    // <== changed this line
    return {
      advisor: null,
      prospect: null,
      advisorVanityName: null,
      loading: false,
      showMessage: false,
      messageTitle: "No Financial Advisor Found",
      message:
        "Please contact your financial advisor for access to the Social Security Risk Score Assessment.",
    };
  },
  computed: {
    getAdvisor() {
      return this.advisor;
    },
    getProspect() {
      return this.prospect;
    },
  },
  created() {
    this.loading = true;
    this.env = process.env.VUE_APP_SCORE_ENV;
    // console.log("env", this.env);

    const vanityName = this.$route.params.id;
    const prospectIdQueryString = this.$route.query["pid"];

    if (!vanityName) {
      this.loading = false;
      this.showMessage = true;
      return;
    }

    if (prospectIdQueryString) {
      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const regexValid = regex.test(prospectIdQueryString);

      if (!regexValid) {
        this.loading = false;
        this.showMessage = true;
        this.messageTitle = "Provided ID is not valid";
        this.message = "Please contact your advisor for assistance.";

        return;
      }
    }
    this.setup(vanityName, prospectIdQueryString);
  },
  mounted() {
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     console.log("CURRENT AUTH USER THEN:", user);
    //   })
    //   .catch((error) => {
    //     console.log("CURRENT AUTH USER ERROR", error);
    //   });

    let surveyEnv;
    if (this.env && this.env === "production") {
      surveyEnv = ""; // bc prod survey bucket is: pgriskscoresurvey with no env at end of string
    } else {
      surveyEnv = this.env;
    }

    const existingPolyfillScriptTag = document.getElementById(
      "webComponentPolyfill"
    );

    if (!existingPolyfillScriptTag) {
      let webComponentPolyfill = document.createElement("script");

      webComponentPolyfill.setAttribute(
        "src",
        "https://unpkg.com/@webcomponents/webcomponentsjs@latest/webcomponents-loader.js"
      );
      webComponentPolyfill.setAttribute("id", "webComponentPolyfill");

      document.head.appendChild(webComponentPolyfill);
    }

    const existingScoreSurveyScriptTag =
      document.getElementById("scoreSurveyScript");

    if (!existingScoreSurveyScriptTag) {
      let scoreSurveyScript = document.createElement("script");

      scoreSurveyScript.setAttribute(
        "src",
        `https://pgriskscoresurvey${surveyEnv}.s3.amazonaws.com/dist/plangap-risk-score.min.js`
      );
      scoreSurveyScript.setAttribute("id", "scoreSurveyScript");

      document.head.appendChild(scoreSurveyScript);
    }
  },
  methods: {
    async setup(vanityName, prospectIdQueryString) {
      try {
        const currentCredentials = await Auth.currentCredentials();

        if (currentCredentials.authenticated) {
          this.authMode = "AMAZON_COGNITO_USER_POOLS";
        }

        // If a qs for prospect_id is passed in from URL, check to see if the prospect exists.
        // If so, set prospect. Otherwise, stop. No need to get advisor.
        if (prospectIdQueryString) {
          const prospectResponse = await this.handleQueryProspect(
            prospectIdQueryString
          );

          if (prospectResponse) {
            this.prospect = prospectResponse;
          } else {
            this.prospect = null;
            this.showMessage = true;
            this.messageTitle = "Provided ID could not be found";
            this.message =
              "Sorry, the provided ID could not be found. Please contact your advisor for assistance.";
            this.loading = false;
            return;
          }
        }

        const advisorResponse = await this.handleQueryAdvisor(vanityName);

        if (advisorResponse.items.length > 0) {
          this.advisor = advisorResponse.items[0].advisor;
        } else {
          this.showMessage = true;
          this.advisor = null;

          this.messageTitle = "An Error Occurred";
          this.message =
            "Advisor could not be found. Please contact your advisor for assistance.";
        }
      } catch (error) {
        console.log("Error in setup: ", error);

        this.showMessage = true;
        this.messageTitle = "An Error Occurred";
        this.message =
          "Error: " +
          error.errors[0].errorType +
          ". Please contact your advisor for assistance.";
      } finally {
        this.loading = false;
      }
    },

    async handleQueryAdvisor(vanityName) {
      try {
        const res = await API.graphql({
          query: vanityInfoByVanityName,
          variables: { vanityName: vanityName },
          authMode: this.authMode,
        });

        return res.data.vanityInfoByVanityName;
      } catch (error) {
        console.log("Error getting advisor for survey...", error);

        this.showMessage = true;
        this.messageTitle = "An Error Occurred";
        this.message =
          "Error: " +
          error.errors[0].errorType +
          ". Please contact your advisor for assistance.";
      }
    },
    async handleQueryProspect(prospectIdQueryString) {
      try {
        const res = await API.graphql({
          query: getProspect,
          variables: { id: prospectIdQueryString },
          authMode: this.authMode,
        });
        return res.data.getProspect;
      } catch (error) {
        console.log("Error getting prospect for survey...", error);

        this.showMessage = true;
        this.messageTitle = "An Error Occurred";
        this.message =
          "Error: " +
          error.errors[0].errorType +
          ". Please contact your advisor for assistance.";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-header {
  h1 {
    max-width: 43.75rem; // 700px at 16px base
  }
}

.rs-score-survey-wrap {
  max-width: 1150px;
  margin: 0 auto;
}
</style>
